var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "top-0 navbar navbar-expand-lg position-absolute z-index-3",
      class: _vm.isBlur
        ? _vm.isBlur
        : "shadow-none my-2 navbar-transparent w-100",
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "router-link",
            _vm._b(
              {
                staticClass: "navbar-brand font-weight-bolder ms-lg-0 ms-3",
                class: _vm.isBlur ? "text-dark" : "text-white",
                attrs: { to: "/dashboard" },
              },
              "router-link",
              _vm.$attrs,
              false
            ),
            [_vm._v(" " + _vm._s(_vm.APPTITLE) + " ")]
          ),
          _vm._m(0),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler shadow-none ms-2",
        attrs: {
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navigation",
          "aria-controls": "navigation",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [
        _c("span", { staticClass: "navbar-toggler-icon mt-2" }, [
          _c("span", { staticClass: "navbar-toggler-bar bar1" }),
          _c("span", { staticClass: "navbar-toggler-bar bar2" }),
          _c("span", { staticClass: "navbar-toggler-bar bar3" }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }