<template>
  <div id="app">
    <sidenav
      :custom_class="mcolor"
      :class="[isTransparent, isRTL ? 'fixed-end' : 'fixed-start']"
      v-if="showSidenav"
    />
    <main
      class="main-content position-relative max-height-vh-100 h-100 border-radius-lg pt-3 mt-3"
      :style="isRTL ? 'overflow-x: hidden' : ''"
    >
      <router-view />
      <app-footer v-show="showFooter" />
      <configurator
        :toggle="toggleConfigurator"
        :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
      />
    </main>
  </div>
</template>
<script>
import { mapState } from "vuex";

import Sidenav from "./layout/Sidenav";
import Configurator from "@/layout/Configurator.vue";
import AppFooter from "@/layout/Footer.vue";
import { mapMutations } from "vuex";
export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    AppFooter,
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky":
          this.$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2":
          this.$store.state.isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
    ...mapState({
      mcolor: (state) => state.mcolor,
      isTransparent: (state) => state.isTransparent,
      showSidenav: (state) => state.showSidenav,
      isAbsolute: (state) => state.isAbsolute,
      isRTL: (state) => state.isRTL,
      showFooter: (state) => state.showFooter,
      showConfig: (state) => state.showConfig,
      hideConfigButton: (state) => state.hideConfigButton,
    }),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
  mounted() {
    console.log(" process.env.NODE_ENV:::", process.env.NODE_ENV);
  },
};
</script>
