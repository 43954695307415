export default {
    login: '/merchant/account/login/index',

    categorycreate: '/mall/category/create/index',
    categorylist: '/mall/category/lists/index',
    categoryremove: '/mall/category/remove/index',
    categoryupdate: '/mall/category/update/index',
    categoryupload: '/mall/category/upload/index',
    productlist: '/mall/products/lists/index',
    productdetail: '/mall/products/detail/index',
    productcreate: '/mall/products/create/index',
    productupdate: '/mall/products/update/index',
    productupdatebatch: '/mall/products/update/batchstatus', //批量更新
    productremove: '/mall/products/remove/index',
    upload: '/mall/common/upload/index',//上传至服务器本地[主要用于分类图上传]
    remove_file: '/mall/common/upload/removefile', //删除服务器文件[主要用于分类图上传]
    ossupload: '/mall/common/ossupload/index', //上传至oss
    remove_oss_file: '/mall/common/ossupload/remove_file',//删除oss文件或文件夹
    orderslist: '/mall/orders/lists/index',
    orderdetail: '/mall/orders/detail/index',
    orderdetele: '/mall/orders/remove/index',
    orderupdate: '/mall/orders/update/index',
    orderupdate_note: '/mall/orders/update/adminnote',
    orderupdate_waybillno: '/mall/orders/update/waybillno',

    product_attributes: '/mall/products/attributes/index', //单商品的属性及SKU
    product_attributes_add: '/mall/products/attributes/add', //添加单商品的一级属性
    product_attributes_remove: '/mall/products/attributes/remove', //删除单商品的一级属性
    product_objects_add: '/mall/products/attributeobject/add', //添加单商品的二级属性
    product_objects_remove: '/mall/products/attributeobject/remove', //删除单商品的二级属性
    product_objects_update: '/mall/products/attributeobject/update', //提交时保存object.oss
    product_sku_update: '/mall/products/attributesku/update', //提交保存之前更新sku
    product_onsale_update: '/mall/products/marketsale/update', //提交保存之前更新促销活动信息

    systemattributes: '/mall/attributes/lists/index',
    //顺丰打印
    sfexpress_create: '/mall/sfexpress/create_order/index', //申请运单号
    request_sheet: '/mall/sfexpress/print_sheet/request_print', //申请运单PDF
    print_sheet: '/mall/sfexpress/print_sheet/print_sheet', //开始打印pdf
    sfexpress_update: '/mall/sfexpress/update_order/index', //主要用于取消面单
    // 退款
    wechatpay_refund: '/pay/wechatpay/refund/index', //微信退款
    alipay_refund: '/pay/alipay/refund/index', //支付宝退款
    paypal_refund: '/pay/paypal/refund/index', //paypal退款
    applepay_refund: '/pay/applepay/refund/index', //支付宝退款
    douyinpay_refund: '/pay/douyin/refund/index', //抖音退款
    // 优惠券
    coupon_list: '/mall/coupon/lists/index',
    coupon_remove: '/mall/coupon/remove/index', // 支付单个删除或批量删除
    coupon_detail: '/mall/coupon/detail/index',
    coupon_create: '/mall/coupon/create/index',
    coupon_update: '/mall/coupon/update/index',
    coupon_update_status: '/mall/coupon/updatestatus/index',

    //推荐系列
    recommend_list: '/mall/recommend/lists/index',
    recommend_detail: '/mall/recommend/detail/index',
    recommend_create: '/mall/recommend/create/index',
    recommend_update: '/mall/recommend/update/index',
    recommend_remove: '/mall/recommend/remove/index',
    recommend_update_status: '/mall/recommend/updatestatus/index',
    recommend_update_products: '/mall/recommend/updateproducts/index',

    //商家
    merchant_list: '/merchant/provider/lists/index',
    merchant_detail: '/merchant/provider/detail/index',
    merchant_update: '/merchant/provider/update/index',
    merchant_remove: '/merchant/provider/remove/index',

    Authorizer_weapp_detail: "/merchant/wechat/authorizer_detail/index", //商户获取申请成功的小程序基本信息（主要是小程序二维码） 
    Authorizer_codeauditquota: "/merchant/wechat/authorizer_codeauditquota/index", //查询appid绑定服务商代码提交审核额度
    merchant_codetemplates: "/merchant/wechat/codetemplates/index",//获取模板列表
    Authorizer_getallcategories: "/merchant/wechat/authorizer_categories/getallcategories",//获取可设置的所有类目
    Authorizer_category: "/merchant/wechat/authorizer_categories/getcategory",//获取已设置的类目
    Authorizer_addcategory: "/merchant/wechat/authorizer_categories/addcategory",//添加类目
    Authorizer_checkwxverifynickname: "/merchant/wechat/authorizer_setting/checkwxverifynickname",//小程序名称检测
    Authorizer_setnickname: "/merchant/wechat/authorizer_setting/setnickname",//小程序名称检测

    Authorizer_previewediton: "/merchant/wechat/authorizer_codecommit/previewediton",//上传代码并生成体验版

    Authorizer_auditedition: "/merchant/wechat/authorizer_codecommit/auditedition",//检验版提交成功后，再提交审核

    Authorizer_previewqrcode: "/merchant/wechat/authorizer_codecommit/previewqrcode",//获取体验版二维码




    //平台小程序二维码
    platformWeappQrcode: "/static/assets/images/gh_03c5445be624_258-2.jpg",

    //团长
    clerk_list: '/merchant/clerk/lists/index',
    clerk_detail: '/merchant/clerk/detail/index',
    clerk_update: '/merchant/clerk/update/index',
    //会员
    user_list: '/user/lists/index',

    //跳转至第三方 
    douyin_create_press: "/pay/douyin/authorization/createpress/index",
    douyin_bind_whitelist: "/pay/douyin/authorization/bind_whitelist/index",
    douyin_publish_sharetoh5: "/pay/douyin/authorization/sharetoh5/index"


}