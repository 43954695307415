import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Dashboard from "@/views/Dashboard.vue";

import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";

Vue.use(Vuex)
Vue.use(VueRouter)
import store from "@/store";
const routes = [
  //views
  {
    path: "/",
    name: "Home",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: "仪表板",
      auth: true //是否登录
    },
    props: {
      name: "仪表板"
    }
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },


  //pages
  {
    path: "/ecommerce",
    name: "ecommerceoverview",
    meta: {
      title: "电商总览",
      navigator: ['电商平台'],
      auth: true
    },
    component: () => import('../pages/ecommerce.vue'),
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import('../pages/orders/index.vue'),
    children: [
      {
        path: "/orders/list/:offset",
        name: "orderList",
        meta: {
          title: "订单列表",
          navigator: ['电商平台', '市场'],
          auth: true
        },
        component: () => import('../pages/orders/list.vue'),
      },
      {
        path: "/orders/detail",
        name: "orderDetail",
        meta: {
          title: "订单详情",
          navigator: ['电商平台', '市场', '订单'],
          auth: true
        },
        component: () => import('../pages/orders/detail.vue'),
      },
    ]
  },
  {
    path: "/category/list",
    name: "categoryList",
    meta: {
      title: "商品类别",
      navigator: ['电商平台', '市场'],
      auth: true
    },
    component: () => import('../pages/category/list.vue'),
  },
  {
    name: "productList",
    path: "/products/list/:offset",
    component: () => import('../pages/products/list.vue'),
    meta: {
      title: "商品列表",
      navigator: ['电商平台', '市场'],
      auth: true
    },
  },
  {
    name: "productDetail",
    path: "/products/detail",
    component: () => import('../pages/products/detail.vue'),
    meta: {
      title: "商品详情",
      navigator: ['电商平台', '市场'],
      auth: true
    },
  },
  {
    name: "couponList",
    meta: {
      title: "优惠券列表",
      navigator: ['电商平台', '市场'],
      auth: true
    },
    path: "/coupons/list/:offset",
    component: () => import('../pages/coupons/list.vue'),

  },
  {
    path: "/coupons/detail",
    name: "couponDetail",
    meta: {
      title: "优惠券详情",
      navigator: ['电商平台', '市场'],
      auth: true
    },
    component: () => import('../pages/coupons/detail.vue'),
  },
  {
    name: "recommendList",
    meta: {
      title: "推荐系列",
      navigator: ['电商平台', '市场'],
      auth: true
    },
    component: () => import('../pages/recommend/list.vue'),
    path: "/recommend/list/:offset",
  },
  {
    name: "recommendDetail",
    meta: {
      title: "推荐系列详情",
      navigator: ['电商平台', '市场'],
      auth: true
    },
    component: () => import('../pages/recommend/detail.vue'),
    path: "/recommend/detail",
  },
  {
    name: "userList",
    component: () => import('../pages/users/list.vue'),
    path: "/users/list/:offset",
    meta: {
      title: "会员列表",
      navigator: ['电商平台', '市场'],
      auth: true
    },
  },
  {
    path: "/users/detail",
    name: "userDetail",
    meta: {
      title: "会员详情",
      navigator: ['电商平台', '市场'],
      auth: true
    },
    component: () => import('../pages/users/detail.vue'),
  },
  {
    path: "/merchant",
    name: "merchant",
    component: () => import('../pages/merchant/index.vue'),
    children: [

      {
        path: "/merchant/overview",
        name: "merchantoverview",
        meta: {
          title: "商户总览",
          navigator: ['商户系统'],
          auth: true
        },
        component: () => import('../pages/merchant/overview.vue'),
      },
      {
        path: "/merchant/list/:offset",
        name: "merchantList",
        meta: {
          title: "商家列表",
          navigator: ['商户系统'],
          auth: true
        },
        component: () => import('../pages/merchant/provider/list.vue')
      },
      {
        path: "/merchant/detail",
        name: "merchantDetail",
        meta: {
          title: "商户详情",
          navigator: ['商户系统', '商家'],
          auth: true
        },
        component: () => import('../pages/merchant/provider/detail.vue')
      },
      {
        path: "/clerk/list/:offset",
        name: "clerkList",
        meta: {
          title: "团长列表",
          navigator: ['商户系统'],
          auth: true
        },
        component: () => import('../pages/merchant/clerk/list.vue')
      },
      {
        path: "/clerk/detail",
        name: "clerkDetail",
        meta: {
          title: "团长详情",
          navigator: ['商户系统', '团长'],
          auth: true
        },
        component: () => import('../pages/merchant/clerk/detail.vue')
      },
    ]
  },


];
const router = new VueRouter({
  //mode: 'history', //history or hash
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'Acovar Pro';
  }

  if (to.meta.auth) {
    //console.log('router token', store.state.token)
    if (store.state.token) {
      store.state.showSidenav = true;
      next()
    } else {
      next({
        path: '/signin', // debug to /
        query: { redirect: to.path }
      })
    }
  } else {
    next()
  }
});



export default router;
