<template>
  <div class="nav-link" :class="{ active: isActive }">
    <div
      class="text-center bg-white shadow icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center"
      :class="this.$store.state.isRTL ? ' ms-2' : 'me-2'"
    >
      <slot name="icon"></slot>
    </div>
    <span
      class="nav-link-text"
      :class="this.$store.state.isRTL ? ' me-1' : 'ms-1'"
      >{{ navText }}</span
    >
  </div>
</template>
<script>
export default {
  name: "sidenav-collapse",
  props: {
    navText: {
      type: String,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: true,
    };
  },
};
</script>
