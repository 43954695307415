var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    {
      staticClass:
        "my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl",
      class: _vm.isRTL ? "me-3 rotate-caret" : "ms-3",
      attrs: { id: "sidenav-main" },
    },
    [
      _c("div", { staticClass: "sidenav-header" }, [
        _c("i", {
          staticClass:
            "top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none",
          attrs: { "aria-hidden": "true", id: "iconSidenav" },
        }),
        _c("a", { staticClass: "m-0 navbar-brand", attrs: { href: "/" } }, [
          _c("img", {
            staticClass: "navbar-brand-img h-100",
            attrs: { src: _vm.logo, alt: "main_logo" },
          }),
          _c("span", { staticClass: "ms-1 font-weight-bold" }, [_vm._v("PRO")]),
        ]),
      ]),
      _c("hr", { staticClass: "mt-0 horizontal dark" }),
      _c("sidenav-list", { attrs: { cardBg: _vm.customClass } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }