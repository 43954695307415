var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-link", class: { active: _vm.isActive } },
    [
      _c(
        "div",
        {
          staticClass:
            "text-center bg-white shadow icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center",
          class: this.$store.state.isRTL ? " ms-2" : "me-2",
        },
        [_vm._t("icon")],
        2
      ),
      _c(
        "span",
        {
          staticClass: "nav-link-text",
          class: this.$store.state.isRTL ? " me-1" : "ms-1",
        },
        [_vm._v(_vm._s(_vm.navText))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }