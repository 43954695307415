var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.token
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100",
            attrs: { id: "sidenav-collapse-main" },
          },
          [
            _c("ul", { staticClass: "navbar-nav" }, [
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c("sidenav-collapse", {
                    attrs: {
                      navText: "仪表盘",
                      to: { name: "Dashboard" },
                      "data-bs-toggle": "Dashboard",
                      href: "#Dashboard",
                      role: "button",
                      "aria-expanded": "false",
                      "aria-controls": "Dashboard",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("span", {
                                staticClass: "fa fa-gauge sideicon",
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2323739333
                    ),
                  }),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c("sidenav-title", {
                    attrs: {
                      navText: "电商平台",
                      "data-bs-toggle": "collapse",
                      "data-bs-target": "#ecommerce",
                      "aria-controls": "ecommerce",
                      "aria-expanded": "true",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("span", {
                                staticClass: "fa fa-shop sideicon",
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3984939760
                    ),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "collapse show", attrs: { id: "ecommerce" } },
                [
                  _c("ul", { staticClass: "nav ms-4 ps-3" }, [
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: { to: "/ecommerce" },
                          },
                          [
                            _c("small", { staticClass: "fa fa-chart-line" }),
                            _c("span", { staticClass: "ms-2" }, [
                              _vm._v("总览"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("li", { staticClass: "nav-item" }, [
                      _vm._m(0),
                      _c(
                        "div",
                        {
                          staticClass: "collapse show",
                          attrs: { id: "products" },
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "nav nav-sm flex-column ms-3" },
                            [
                              _c(
                                "li",
                                { staticClass: "nav-item" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        to:
                                          "/orders/list/1?timer=" +
                                          new Date().getTime(),
                                      },
                                    },
                                    [_vm._v(" 订单列表 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "li",
                                { staticClass: "nav-item" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: { to: "/category/list" },
                                    },
                                    [_vm._v(" 商品类别 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "li",
                                { staticClass: "nav-item" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: { to: "/products/list/1" },
                                    },
                                    [_vm._v(" 商品列表 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "li",
                                { staticClass: "nav-item" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        to: "/coupons/list/1",
                                        replace: "",
                                      },
                                    },
                                    [_vm._v(" 优惠券 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "li",
                                { staticClass: "nav-item" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: { to: "/recommend/list/1" },
                                    },
                                    [_vm._v(" 推荐系列 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("li", { staticClass: "nav-item" }, [
                      _vm._m(1),
                      _c(
                        "div",
                        {
                          staticClass: "collapse show",
                          attrs: { id: "users" },
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "nav nav-sm flex-column ms-3" },
                            [
                              _c(
                                "li",
                                { staticClass: "nav-item" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: { to: "/users/list/1" },
                                    },
                                    [_vm._v(" 会员列表 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c("sidenav-title", {
                    attrs: {
                      navText: "商户系统",
                      "data-bs-toggle": "collapse",
                      "data-bs-target": "#merchants",
                      "aria-controls": "merchants",
                      "aria-expanded": "true",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("span", {
                                staticClass: "fa fa-briefcase sideicon",
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3477676410
                    ),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "collapse show", attrs: { id: "merchants" } },
                [
                  _c("ul", { staticClass: "nav ms-4 ps-3" }, [
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: { to: "/merchant/overview" },
                          },
                          [
                            _c("small", [
                              _c("i", { staticClass: "fa fa-chart-simple" }),
                            ]),
                            _c("span", { staticClass: "ms-2" }, [
                              _vm._v("总览"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("li", { staticClass: "nav-item" }, [
                      _vm._m(2),
                      _c(
                        "div",
                        {
                          staticClass: "collapse show",
                          attrs: { id: "provider" },
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "nav nav-sm flex-column ms-3" },
                            [
                              _c(
                                "li",
                                { staticClass: "nav-item" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: { to: "/merchant/list/1" },
                                    },
                                    [_vm._v(" 商家列表 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "li",
                                { staticClass: "nav-item" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: { to: "/clerk/list/1" },
                                    },
                                    [_vm._v(" 团长列表 ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("li", { staticClass: "mt-3 nav-item" }, [
                _c(
                  "h6",
                  {
                    staticClass:
                      "text-xs ps-4 text-uppercase font-weight-bolder opacity-6",
                    class: this.$store.state.isRTL ? "me-4" : "ms-2",
                  },
                  [_vm._v(" 登录信息 ")]
                ),
              ]),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c("sidenav-collapse", {
                    attrs: { navText: "当前账户", to: { name: "" } },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [_c("customer-support")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2368766757
                    ),
                  }),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c("sidenav-title", {
                    attrs: { navText: _vm.login_status },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [_c("document")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      653473548
                    ),
                  }),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "nav-item" },
                [
                  _c("sidenav-title", {
                    attrs: { navText: _vm.user.phonenumber },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "icon",
                          fn: function () {
                            return [_c("spaceship")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3968310117
                    ),
                  }),
                ],
                1
              ),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.douyin_bind_whitelist,
                      target: "_blank",
                    },
                  },
                  [
                    _c("sidenav-title", {
                      attrs: { navText: "绑定抖音" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("small", {
                                  staticClass: "fa-brands fa-tiktok",
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        508737460
                      ),
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "mx-3 sidenav-footer" },
          [
            _c(
              "el-popconfirm",
              {
                attrs: {
                  title: "确定登出当前账户吗？",
                  "confirm-button-text": "退出",
                  "cancel-button-text": "再看看",
                  icon: "el-icon-info",
                  "icon-color": "red",
                },
                on: { confirm: _vm.quit },
              },
              [
                _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn bg-gradient-info mt-4 w-100",
                      attrs: { type: "button" },
                    },
                    [_vm._v(" 退出登录 ")]
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link",
        attrs: {
          "data-bs-toggle": "collapse",
          "aria-expanded": "true",
          href: "#products",
        },
      },
      [
        _c("small", { staticClass: "fa fa-chart-pie" }),
        _c("span", { staticClass: "ms-2" }, [_vm._v("市场 ")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link",
        attrs: {
          "data-bs-toggle": "collapse",
          "aria-expanded": "true",
          href: "#users",
        },
      },
      [
        _c("small", { staticClass: "fa fa-users" }),
        _c("span", { staticClass: "ms-2" }, [_vm._v("会员 ")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link",
        attrs: {
          "data-bs-toggle": "collapse",
          "aria-expanded": "true",
          href: "#provider",
        },
      },
      [
        _c("small", { staticClass: "fa fa-user-group" }),
        _c("span", { staticClass: "ms-2" }, [_vm._v("商家 ")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }