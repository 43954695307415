let recommendstore = {
    "name": "",
    "description": "",
    "date_created": "",
    "date_updated": "",
    "status": "1",
    "views": "",
    "merchant_id": 0,
    "category_id": ""
}

export default { recommendstore: recommendstore }