let onsale = {
    spike: {
        date_start: "",
        date_end: "",
        price_onsale: "",
        sale_type: "spike",
        status: false,
    },
    groups: {
        date_start: "",
        date_end: "",
        price_onsale: "",
        sale_type: "groups",
        status: false,
    },
    friends: {
        date_start: "",
        date_end: "",
        price_onsale: "",
        sale_type: "friends",
        status: false,
    },
};

export default { onsale: onsale };