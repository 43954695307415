var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "py-4 container-fluid" }, [
    _c("h1", [_vm._v("仪表盘")]),
    _c("div", [_vm._v(_vm._s(_vm.translateText))]),
    _c("div", {}, [
      _vm._v(
        " 当前登录账号：" +
          _vm._s(
            _vm.user.phonenumber +
              " （ID: " +
              _vm.user.id +
              " ) " +
              _vm.user.source
          ) +
          " "
      ),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "px-4 pt-5 my-5 text-center border-bottom" },
      [
        _c("h1", { staticClass: "display-4 fw-bold" }, [
          _vm._v("系统全量数据统计"),
        ]),
        _c("div", { staticClass: "col-lg-6 mx-auto" }, [
          _c("p", { staticClass: "lead mb-4" }, [_vm._v("Coming Soon")]),
        ]),
        _c(
          "div",
          {
            staticClass: "overflow-hidden",
            staticStyle: { "max-height": "30vh" },
          },
          [_c("div", { staticClass: "container px-5" })]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }