let coupondata = {
    "source": "paltform",
    "name": "",
    "merchant_id": "0",
    "content": {
        "full": "0",
        "type": "discount",
        "reduction": "5",
        "discount": "12"
    },
    "quantity_received": null,
    "quantity_used": null,
    "date_start": "",
    "date_end": "",
    "date_create": "",
    "date_update": "",
    "status": "",
    "action_id": null,
    "merchant_name": null,
    "merchant_postion": {
        "flag": "http://www.c.com//static/countries/flag/chn.png",
        "state": {
            "id": 45,
            "iso2": "CN",
            "iso3": "CHN",
            "name": "China",
            "emoji": "🇨🇳",
            "emojiU": "U+1F1E8 U+1F1F3",
            "native": "中国大陆",
            "region": "Asia",
            "capital": "Beijing",
            "currency": "CNY",
            "subregion": "Eastern Asia",
            "timezones": [
                {
                    "tzName": "China Standard Time",
                    "zoneName": "Asia/Shanghai",
                    "gmtOffset": 28800,
                    "abbreviation": "CST",
                    "gmtOffsetName": "UTC+08:00"
                },
                {
                    "tzName": "China Standard Time",
                    "zoneName": "Asia/Urumqi",
                    "gmtOffset": 21600,
                    "abbreviation": "XJT",
                    "gmtOffsetName": "UTC+06:00"
                }
            ],
            "phone_code": "86"
        },
        "status": 200,
        "message": "success",
        "currency": {
            "area": "The People\"s Republic of China",
            "name": "中国",
            "value": "1",
            "symbol": "¥",
            "currency": "CNY",
            "imageUrl": "assets/flags/CHN--China.png",
            "selected": false,
            "currencyName": "Chinese Yuan",
            "currencyNameCN": "人民币"
        }
    },
    "merchant_description": null,
    "merchant_telephone": null,
    "merchant_status": "1",
    "action_name": null,
    "action_source": null,
    "action_quantity_total": null,
    "action_target_number": null,
    "action_status": null
};


export default { coupondata: coupondata };