<template>
  <div v-if="token">
    <div
      class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
      id="sidenav-collapse-main"
    >
      <ul class="navbar-nav">
        <li class="nav-item">
          <sidenav-collapse
            navText="仪表盘"
            :to="{ name: 'Dashboard' }"
            data-bs-toggle="Dashboard"
            href="#Dashboard"
            role="button"
            aria-expanded="false"
            aria-controls="Dashboard"
          >
            <template #icon>
              <span class="fa fa-gauge sideicon"></span>
            </template>
          </sidenav-collapse>
        </li>

        <li class="nav-item">
          <sidenav-title
            navText="电商平台"
            data-bs-toggle="collapse"
            data-bs-target="#ecommerce"
            aria-controls="ecommerce"
            aria-expanded="true"
          >
            <template #icon>
              <span class="fa fa-shop sideicon"></span>
            </template>
          </sidenav-title>
        </li>

        <div class="collapse show" id="ecommerce">
          <ul class="nav ms-4 ps-3">
            <li class="nav-item">
              <router-link class="nav-link" :to="'/ecommerce'"
                ><small class="fa fa-chart-line"></small
                ><span class="ms-2">总览</span>
              </router-link>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                aria-expanded="true"
                href="#products"
              >
                <small class="fa fa-chart-pie"></small>
                <span class="ms-2">市场 </span></a
              >
              <div id="products" class="collapse show" style="">
                <ul class="nav nav-sm flex-column ms-3">
                  <li class="nav-item">
                    <router-link
                      :to="'/orders/list/1?timer=' + new Date().getTime()"
                      class="nav-link"
                    >
                      订单列表
                    </router-link>
                  </li>

                  <li class="nav-item">
                    <router-link to="/category/list" class="nav-link">
                      商品类别
                    </router-link>
                  </li>

                  <li class="nav-item">
                    <router-link to="/products/list/1" class="nav-link">
                      商品列表
                    </router-link>
                  </li>

                  <li class="nav-item">
                    <router-link to="/coupons/list/1" class="nav-link" replace>
                      优惠券
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/recommend/list/1" class="nav-link">
                      推荐系列
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                aria-expanded="true"
                href="#users"
              >
                <small class="fa fa-users"></small>
                <span class="ms-2">会员 </span>
              </a>
              <div id="users" class="collapse show" style="">
                <ul class="nav nav-sm flex-column ms-3">
                  <li class="nav-item">
                    <router-link to="/users/list/1" class="nav-link">
                      会员列表
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <li class="nav-item">
          <sidenav-title
            navText="商户系统"
            data-bs-toggle="collapse"
            data-bs-target="#merchants"
            aria-controls="merchants"
            aria-expanded="true"
          >
            <template #icon>
              <span class="fa fa-briefcase sideicon"></span>
            </template>
          </sidenav-title>
        </li>

        <div class="collapse show" id="merchants">
          <ul class="nav ms-4 ps-3">
            <li class="nav-item">
              <router-link class="nav-link" :to="'/merchant/overview'"
                ><small><i class="fa fa-chart-simple"></i></small
                ><span class="ms-2">总览</span>
              </router-link>
            </li>

            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                aria-expanded="true"
                href="#provider"
              >
                <small class="fa fa-user-group"></small>
                <span class="ms-2">商家 </span>
              </a>
              <div id="provider" class="collapse show" style="">
                <ul class="nav nav-sm flex-column ms-3">
                  <li class="nav-item">
                    <router-link to="/merchant/list/1" class="nav-link">
                      商家列表
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/clerk/list/1" class="nav-link">
                      团长列表
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <li class="mt-3 nav-item">
          <h6
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
            :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
          >
            登录信息
          </h6>
        </li>
        <li class="nav-item">
          <sidenav-collapse navText="当前账户" :to="{ name: '' }">
            <template #icon>
              <customer-support />
            </template>
          </sidenav-collapse>
        </li>
        <li class="nav-item">
          <sidenav-title :navText="login_status">
            <template #icon>
              <document />
            </template>
          </sidenav-title>
        </li>
        <li class="nav-item">
          <sidenav-title :navText="user.phonenumber">
            <template #icon>
              <spaceship />
            </template>
          </sidenav-title>
        </li>
        <li class="nav-item">
          <a :href="douyin_bind_whitelist" target="_blank">
            <sidenav-title navText="绑定抖音">
              <template #icon>
                <small class="fa-brands fa-tiktok"></small>
              </template>
            </sidenav-title>
          </a>
        </li>
      </ul>
    </div>
    <div class="mx-3 sidenav-footer">
      <el-popconfirm
        title="确定登出当前账户吗？"
        confirm-button-text="退出"
        cancel-button-text="再看看"
        icon="el-icon-info"
        icon-color="red"
        @confirm="quit"
      >
        <div slot="reference">
          <button class="btn bg-gradient-info mt-4 w-100" type="button">
            退出登录
          </button>
        </div>
      </el-popconfirm>
    </div>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavTitle from "./SidenavTitle.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import CreditCard from "../../components/Icon/CreditCard.vue";
import Box3d from "../../components/Icon/Box3d.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Document from "../../components/Icon/Document.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
import Settings from "../../components/Icon/Settings.vue";

import { mapState } from "vuex";
export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  computed: {
    ...mapState({
      baseURL: (state) => state.baseURL,
      user: (state) => state.user,
      token: (state) => state.token,
    }),
  },
  data() {
    return {
      routeParams: {},
      login_status: "",
      douyin_bind_whitelist: "",
    };
  },
  components: {
    SidenavTitle,
    SidenavCollapse,
    Shop,
    Office,
    CreditCard,
    Box3d,
    CustomerSupport,
    Document,
    Spaceship,
    Settings,
  },
  watch: {
    $route: "getRoute", //监听路由变化 ， 导航高亮
  },
  created() {
    this.douyin_bind_whitelist =
      this.baseURL + this.$urls.douyin_bind_whitelist + "?scopetype=whitelist";
    console.log("create", this.douyin_bind_whitelist);
  },
  mounted() {
    let merchant_type = "";
    if (this.user.source == "merchant" && this.user.role == "owner") {
      merchant_type = "商户";
    } else if (this.user.source == "platform") {
      merchant_type = "系统管理员";
    }

    this.login_status = this.user.name || merchant_type || "登录失败";
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      let pathname = routeArr[1];
      //console.log("sidenav", this.$route);
      return pathname;
    },
    collapse() {},

    quit() {
      localStorage.clear();

      //console.log("token a", localStorage.token);
      this.$store.state.token = "";
      this.$router.replace("/signin");
    },
  },
};
</script>
<style scoped>
.active .sideicon {
  color: #fff;
}

.navbar-vertical
  .navbar-nav
  .nav-item
  .collapse
  .nav
  .nav-item
  .nav-link:before,
.navbar-vertical
  .navbar-nav
  .nav-item
  .collapsing
  .nav
  .nav-item
  .nav-link:before {
  left: 0;
}
</style>