var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "py-5 footer" }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "mx-auto mt-1 text-center col-8" }, [
          _c("p", { staticClass: "mb-0 text-secondary" }, [
            _vm._v(
              " Copyright © " +
                _vm._s(new Date().getFullYear()) +
                " 上海木荷电子商务有限公司. "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "mx-auto mt-2 mb-4 text-center col-lg-8" }, [
        _c(
          "a",
          {
            staticClass: "text-secondary me-xl-4 me-4",
            attrs: { href: "#", target: "_blank" },
          },
          [_c("span", { staticClass: "text-lg fab fa-wechat" })]
        ),
        _c(
          "a",
          {
            staticClass: "text-secondary me-xl-4 me-4",
            attrs: { href: "#", target: "_blank" },
          },
          [_c("span", { staticClass: "text-lg fab fa-tiktok" })]
        ),
        _c(
          "a",
          {
            staticClass: "text-secondary me-xl-4 me-4",
            attrs: { href: "#", target: "_blank" },
          },
          [_c("span", { staticClass: "text-lg fab fa-weibo" })]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }