
let baseURL = process.env.VUE_APP_BASEURL;
let mallURL = process.env.VUE_APP_MALLURL;

let basedata = {
    baseURL: baseURL,
    mallURL: mallURL,
    token: localStorage.token || '',
    user: localStorage.user ? JSON.parse(localStorage.user) : {},
    categorylist: [],
};
export default { basedata: basedata };