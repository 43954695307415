var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "p-3 m-3 footer" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "row align-items-center justify-content-lg-between" },
        [
          _c("div", { staticClass: "mb-4 col-lg-6 mb-lg-0" }, [
            _c(
              "div",
              {
                staticClass: "text-sm text-center copyright text-muted",
                class: this.$store.state.isRTL
                  ? "text-lg-end"
                  : "text-lg-start",
              },
              [
                _vm._v(" © " + _vm._s(new Date().getFullYear()) + " "),
                _c("i", { staticClass: "fa fa-heart" }),
                _vm._v(" by "),
                _c(
                  "a",
                  {
                    staticClass: "font-weight-bold",
                    attrs: { href: "https://api.acovar.com", target: "_blank" },
                  },
                  [_vm._v("Acovar.com")]
                ),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }