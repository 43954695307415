
import axios from "axios";
import store from "@/store"
import router from "@/router"
import { Loading } from 'element-ui';

let timestamp = new Date().getTime();
let config = {
    // 每次请求的协议、IP地址。  设置该配置后，每次请求路径都可以使用相对路径，例如"/admin/login"
    baseURL: store.state.baseURL,//"http://www.c.com",
    method: 'post',
    // 请求超时时间
    timeout: 0,
    crossDomain: true,
    // 每次请求携带token
    headers: {
        common: {
            'Access-Control-Allow-Origin': '*',
        },
        Client: 'management', //管理平台客户端发出的请求
        Signature: window.btoa('Client=management&key=sign&value=merchant&timestamp=' + timestamp) //value = [user,merchant]
    },
    onUploadProgress: (progress) => {
        let percent = Math.floor(progress.loaded / progress.total * 100)
    },
    onDownloadProgress: (ProgressEvent) => {
        //console.log(ProgressEvent)
    },
    loading: false
}
let instance = axios.create(config);

let loading = null;

instance.interceptors.request.use(
    config => {
        loading = Loading.service({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.3)',
            text: 'loading...',
            customClass: 'text-muted'
        });



        config.headers.Authorization = localStorage.token
        //console.log('request', config)

        return config;
    },
    error => {
        // 立即执行失败的reject方法
        console.log(error.toString());
        return Promise.reject(error);
    }
);

// 配置响应拦截器 
instance.interceptors.response.use(
    response => {

        loading.close();

        if (response.data.status === 401) {
            localStorage.clear();
            //window.location.href = '/login';
            router.replace('/signin');
        }
        return Promise.resolve(response.data) // 这里直接返回data, 即接口返回的所有数据
    }, error => {

        loading.close();
        console.log(error.toString());
        if (error.response.status === 401) {
            console.log('unauthorized, logging out ...');
            localStorage.clear();
            router.replace('/signin');
        }
        return Promise.reject(error);
    }
)

export default instance