<template>
  <div>
    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
          <navbar
            is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
            btn-background="bg-gradient-success"
            :dark-mode="true"
          />
        </div>
      </div>
    </div>
    <main class="mt-0 main-content main-content-bg">
      <section>
        <div class="page-header min-vh-75">
          <div class="container">
            <div class="row">
              <div
                class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column"
              >
                <div class="mt-8 card card-plain">
                  <div class="pb-0 card-header text-start">
                    <h3 class="font-weight-bolder text-success text-gradient">
                      欢迎回来 <small> Welcome back</small>
                    </h3>

                    <p class="mb-0">
                      请输入你的邮箱和密码进行登录<br /><small
                        ><i> Enter your email and password to sign in</i></small
                      >
                    </p>
                  </div>
                  <div class="card-body">
                    <div class="text-start">
                      <el-form :model="loginForm" ref="loginForm">
                        <label>手机号码或电子邮箱</label>
                        <el-form-item
                          prop="username"
                          :rules="[
                            {
                              required: true,
                              message: '请输入用户名',
                              trigger: ['blur', 'change'],
                            },
                            {
                              min: 6,
                              max: 32,
                              message: '长度在 6 到 32 个字符',
                              trigger: ['blur', 'change'],
                            },
                          ]"
                        >
                          <el-input
                            v-model="loginForm.username"
                            placeholder="Username"
                            suffix-icon="el-icon-user"
                          >
                          </el-input>
                        </el-form-item>

                        <label>登录密码</label>
                        <el-form-item
                          prop="password"
                          :rules="[
                            {
                              required: true,
                              message: '请输入密码',
                              trigger: ['blur', 'change'],
                            },
                            {
                              min: 6,
                              max: 16,
                              message: '长度在 6 到 16 个字符',
                              trigger: ['blur', 'change'],
                            },
                          ]"
                        >
                          <el-input
                            v-model="loginForm.password"
                            type="password"
                            placeholder="Password"
                            suffix-icon="el-icon-lock"
                          >
                          </el-input>
                        </el-form-item>

                        <div class="text-center">
                          <soft-button
                            class="my-4 mb-2"
                            variant="gradient"
                            color="success"
                            full-width
                            :clickFunciton="submitForm"
                            title="Sign In"
                            >登 录
                          </soft-button>
                        </div>
                      </el-form>
                    </div>
                  </div>
                  <div class="px-1 pt-0 text-center card-footer px-lg-2">
                    <p class="mx-auto mb-4 text-sm">
                      还没有账户？
                      <router-link
                        :to="{ name: 'SignUp' }"
                        class="text-success text-gradient font-weight-bold"
                        title="Sign Up"
                        >注册账户</router-link
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
                >
                  <div
                    class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                    :style="{
                      backgroundImage:
                        'url(' +
                        require('@/assets/img/curved-images/curved9.jpg') +
                        ')',
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <app-footer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/layout/SignLayout/Navbar.vue";
import AppFooter from "@/layout/SignLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
//import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
  name: "SignIn",
  components: {
    Navbar,
    AppFooter,
    SoftInput,
    //SoftSwitch,
    SoftButton,
  },
  computed: {
    ...mapState({
      isRemember: (state) => state.isRemember,
    }),
  },
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
    };
  },
  created() {
    // this.toggleEveryDisplay();
    // this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    // this.toggleEveryDisplay();
    // this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  mounted() {
    //this.hideNav();
    this.$store.state.showSidenav = false;
  },
  methods: {
    submitForm() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async login() {
      let postdata = {
        username: this.loginForm.username,
        password: this.loginForm.password,
      };
      await this.$axios.post(this.$urls.login, postdata).then((response) => {
        if (response.status == 200 && response.token) {
          localStorage.token = response.token;
          this.$store.state.token = response.token;
          localStorage.user = JSON.stringify(response.user);
          this.$store.state.user = response.user;
          //this.toggleEveryDisplay();
          this.$store.state.showSidenav = false;
          this.$router.replace("/Dashboard");
        } else {
          this.$message(response.message);
        }
      });
    },
  },
};
</script>
