import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//softUI


import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/zh-CN'
import '@/assets/theme/index.css';

import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/font-awesome-all.min.css";
import SoftUIDashboard from "./soft-ui-dashboard";

import '@/assets/style.css';

import axios from './axios/index'
import urls from './axios/urls'
Vue.config.productionTip = false;

Vue.use(SoftUIDashboard)
Vue.use(ElementUI, { locale, size: 'small', zIndex: 3000 })
Vue.prototype.$axios = axios
Vue.prototype.$urls = urls

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
