var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-check" }, [
    _c("input", {
      staticClass: "form-check-input",
      attrs: { id: _vm.id, type: "checkbox", name: _vm.name },
      domProps: { checked: _vm.checked },
    }),
    _c(
      "label",
      {
        staticClass: "custom-control-label",
        class: _vm.$attrs.class,
        attrs: { for: _vm.id },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }