var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn mb-0",
      class: _vm.getClasses(
        _vm.variant,
        _vm.color,
        _vm.size,
        _vm.fullWidth,
        _vm.active
      ),
      on: { click: _vm.clickFunciton },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }