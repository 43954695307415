var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("div", { class: _vm.hasIcon(_vm.icon) }, [
      _vm.iconDir === "left"
        ? _c("span", { staticClass: "input-group-text" }, [
            _c("i", { class: _vm.getIcon(_vm.icon) }),
          ])
        : _vm._e(),
      _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.result,
                expression: "result",
              },
            ],
            staticClass: "form-control",
            class: _vm.getClasses(_vm.size, _vm.success, _vm.error),
            attrs: {
              id: _vm.id,
              name: _vm.name,
              placeholder: _vm.placeholder,
              isRequired: _vm.isRequired,
              type: "checkbox",
            },
            domProps: {
              value: _vm.value,
              checked: Array.isArray(_vm.result)
                ? _vm._i(_vm.result, _vm.value) > -1
                : _vm.result,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.result,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.value,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.result = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.result = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.result = $$c
                }
              },
            },
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.result,
                expression: "result",
              },
            ],
            staticClass: "form-control",
            class: _vm.getClasses(_vm.size, _vm.success, _vm.error),
            attrs: {
              id: _vm.id,
              name: _vm.name,
              placeholder: _vm.placeholder,
              isRequired: _vm.isRequired,
              type: "radio",
            },
            domProps: {
              value: _vm.value,
              checked: _vm._q(_vm.result, _vm.value),
            },
            on: {
              change: function ($event) {
                _vm.result = _vm.value
              },
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.result,
                expression: "result",
              },
            ],
            staticClass: "form-control",
            class: _vm.getClasses(_vm.size, _vm.success, _vm.error),
            attrs: {
              id: _vm.id,
              name: _vm.name,
              placeholder: _vm.placeholder,
              isRequired: _vm.isRequired,
              type: _vm.type,
            },
            domProps: { value: _vm.value, value: _vm.result },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.result = $event.target.value
              },
            },
          }),
      _vm.iconDir === "right"
        ? _c("span", { staticClass: "input-group-text" }, [
            _c("i", { class: _vm.getIcon(_vm.icon) }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }