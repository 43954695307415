var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.showSidenav
        ? _c("sidenav", {
            class: [_vm.isTransparent, _vm.isRTL ? "fixed-end" : "fixed-start"],
            attrs: { custom_class: _vm.mcolor },
          })
        : _vm._e(),
      _c(
        "main",
        {
          staticClass:
            "main-content position-relative max-height-vh-100 h-100 border-radius-lg pt-3 mt-3",
          style: _vm.isRTL ? "overflow-x: hidden" : "",
        },
        [
          _c("router-view"),
          _c("app-footer", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFooter,
                expression: "showFooter",
              },
            ],
          }),
          _c("configurator", {
            class: [
              _vm.showConfig ? "show" : "",
              _vm.hideConfigButton ? "d-none" : "",
            ],
            attrs: { toggle: _vm.toggleConfigurator },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }