<template>
  <div class="py-4 container-fluid">
    <h1>仪表盘</h1>
    <div>{{ translateText }}</div>
    <div class="">
      当前登录账号：{{
        user.phonenumber + " （ID: " + user.id + " ) " + user.source
      }}
    </div>
    <div class="px-4 pt-5 my-5 text-center border-bottom">
      <h1 class="display-4 fw-bold">系统全量数据统计</h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-4">Coming Soon</p>
      </div>
      <div class="overflow-hidden" style="max-height: 30vh">
        <div class="container px-5"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapState } from "vuex";
export default {
  name: "Dashboard",
  components: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      translateText: "Dashboard",
    };
  },
  mounted() {
    //this.iniTranslate();
  },
  methods: {
    iniTranslate() {
      const me = this;
      translate("Ik spreek Engels", { to: "en" })
        .then((res) => {
          me.translateText = res.text;
          console.log(res.text);
          //=> I speak English
          console.log(res.from.language.iso);
          //=> nl
          console.log(res.from.language);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>