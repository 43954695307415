import Vue from 'vue'
import Vuex from 'vuex'
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import storeBase from './storeBase.js';
import storeProduct from './storeProduct.js';
import storeCoupon from './storeCoupon.js';
import storeProductOnSale from './storeProductOnSale.js';
import storeRecommend from './storeRecommend.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //designer
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: false,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,

    //developer
    ...storeBase.basedata,
    ...storeProduct.proudctdata,
    ...storeProductOnSale.onsale,
    postCoupons: storeCoupon.coupondata,
    postRecommend: storeRecommend.recommendstore

  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".sidenav");
      state.showSidenav = false;
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      //state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    hideNav(state) {
      state.showNavbar = false;
      state.showSidenav = false;
      state.showFooter = false;
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    setCardBackground({ commit }, payload) {
      commit("cardBackground", payload);
    },
  },
  getters: {},
});
