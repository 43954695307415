var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fixed-plugin" }, [
    _c(
      "a",
      {
        staticClass: "px-3 py-2 fixed-plugin-button text-dark position-fixed",
        on: { click: _vm.toggle },
      },
      [_c("i", { staticClass: "py-2 fa fa-cog" })]
    ),
    _c("div", { staticClass: "shadow-lg card blur" }, [
      _c("div", { staticClass: "pt-3 pb-0 bg-transparent card-header" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "mt-4 float-end", on: { click: _vm.toggle } },
          [_vm._m(1)]
        ),
      ]),
      _c("hr", { staticClass: "my-1 horizontal dark" }),
      _c("div", { staticClass: "pt-0 card-body pt-sm-3" }, [
        _vm._m(2),
        _c(
          "a",
          {
            staticClass: "switch-trigger background-color",
            attrs: { href: "#" },
          },
          [
            _c(
              "div",
              {
                staticClass: "my-2 badge-colors",
                class: this.$store.state.isRTL ? "text-end" : " text-start",
              },
              [
                _c("span", {
                  staticClass: "badge filter bg-gradient-primary active",
                  attrs: { "data-color": "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.sidebarColor("primary")
                    },
                  },
                }),
                _c("span", {
                  staticClass: "badge filter bg-gradient-dark",
                  attrs: { "data-color": "dark" },
                  on: {
                    click: function ($event) {
                      return _vm.sidebarColor("dark")
                    },
                  },
                }),
                _c("span", {
                  staticClass: "badge filter bg-gradient-info",
                  attrs: { "data-color": "info" },
                  on: {
                    click: function ($event) {
                      return _vm.sidebarColor("info")
                    },
                  },
                }),
                _c("span", {
                  staticClass: "badge filter bg-gradient-success",
                  attrs: { "data-color": "success" },
                  on: {
                    click: function ($event) {
                      return _vm.sidebarColor("success")
                    },
                  },
                }),
                _c("span", {
                  staticClass: "badge filter bg-gradient-warning",
                  attrs: { "data-color": "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.sidebarColor("warning")
                    },
                  },
                }),
                _c("span", {
                  staticClass: "badge filter bg-gradient-danger",
                  attrs: { "data-color": "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.sidebarColor("danger")
                    },
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._m(3),
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "button",
            {
              staticClass: "px-3 mb-2 btn bg-gradient-success w-100",
              class: _vm.ifTransparent === "bg-transparent" ? "active" : "",
              attrs: { id: "btn-transparent" },
              on: {
                click: function ($event) {
                  return _vm.sidebarType("bg-transparent")
                },
              },
            },
            [_vm._v(" 透明 ")]
          ),
          _c(
            "button",
            {
              staticClass: "px-3 mb-2 btn bg-gradient-success w-100 ms-2",
              class: _vm.ifTransparent === "bg-white" ? "active" : "",
              attrs: { id: "btn-white" },
              on: {
                click: function ($event) {
                  return _vm.sidebarType("bg-white")
                },
              },
            },
            [_vm._v(" 白色 ")]
          ),
        ]),
        _c("p", { staticClass: "mt-2 text-sm d-xl-none d-block" }, [
          _vm._v(" You can change the sidenav type just on desktop view. "),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-start" }, [
      _c("h5", { staticClass: "mt-3 mb-0" }, [_vm._v("主题设置")]),
      _c("p", [_vm._v("切换主题皮肤颜色.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "p-0 btn btn-link text-dark fixed-plugin-close-button" },
      [_c("i", { staticClass: "fa fa-close" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h6", { staticClass: "mb-0" }, [_vm._v("侧栏色彩切换")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-3" }, [
      _c("h6", { staticClass: "mb-0" }, [_vm._v("侧栏背景色")]),
      _c("p", { staticClass: "text-sm" }, [_vm._v("透明背景或白色背景")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }