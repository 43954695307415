let proudctdata = {
    //上传图片能用：商品、推荐等
    uploadparams: {},
    fileList: [],
    videoList: [],
    previewList: [],


    //商品详情
    attributeList: [],
    attributeObject: {},
    attributeSku: [],

    ColorAttributes: [],
    descartesObject: [],
    postProducts: { //创建或编辑商品时要提交的数据
        id: null,
        title: "",
        description: null,
        content: null,
        date_created: "",
        date_updated: "",
        status: "pending",
        category_id: [], //[14,17]
        stock_quantity: "99",
        //sales_count: null,
        //rating_count: null,
        pageview_count: null,
        price: "0.0",
        market_price: "0.0",
        cost: "0.0",
        sku: "",
        sort: "10000",
        source_url: "",
        merchant_id: null,
        ratio_cashback: null,
        ratio_clerk: null,
        ratio_seller: null,
        apply_platform: null,
        //sale_date_create: null,
        //sale_date_update: null,
        //date_start: null,
        //date_end: null,
        //price_onsale: null,
        //sale_type: null,
        //sale_status: null,
        oss: [], //[{thumb,large,object},...]
        video: [], //[{url:signurl},...]
        attributes: {
            video: [], //object list
            detail_image: [], //object list
        },
        product_attributes: {
            attribute_list: [],
            attribute_object: {},
            attribute_sku: []
        },
        product_onsale: {
            spike: {
                date_start: "",
                date_end: "",
                price_onsale: "",
                sale_type: "spike",
                status: false,
            },
            groups: {
                date_start: "",
                date_end: "",
                price_onsale: "",
                sale_type: "groups",
                status: false,
            },
            friends: {
                date_start: "",
                date_end: "",
                price_onsale: "",
                sale_type: "friends",
                status: false,
            },
        },
    },
    spike: {
        date_start: "",
        date_end: "",
        price_onsale: "",
        sale_type: "spike",
        status: false,
    },
    groups: {
        date_start: "",
        date_end: "",
        price_onsale: "",
        sale_type: "groups",
        status: false,
    },
    friends: {
        date_start: "",
        date_end: "",
        price_onsale: "",
        sale_type: "friends",
        status: false,
    },
};


export default { proudctdata: proudctdata };